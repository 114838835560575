
















import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from 'vuex-class'

@Component
export default class Footer extends Vue {
    @Getter('config') config: any
}
